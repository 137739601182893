.Navbar {
    display: flex;
    width: 232px;
    min-height: 100vh;
    /* background-color: #389492; */
    background-color: #0A3347;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #0A3347;
    padding-left: 1px;
    padding-bottom: 20px;
    transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
   
}
.Navbar-a {
    display: flex;
    width: 232px;
    min-height: 100vh;
    /* background-color: #389492; */
    background-color: #0A3347;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #0A3347;
    padding-left: 1px;
    padding-bottom: 20px;transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    /* animation: grow 0.4s; */
}

.Navbar-hide {
    display: flex;
    width: 80px;
    min-height: 100vh;
    /* background-color: #389492; */
    background-color: #0A3347;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #0A3347;
    padding-left: 3px;transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}
.Navbar-a-hide {
    display: flex;
    width: 80px;
    min-height: 100vh;
    /* background-color: #389492; */
    background-color: #0A3347;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid #0A3347;
    padding-left: 3px;transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    /* animation: shrink 0.4s; */

}
.fr_icon_navbar {
    width: 40px;
    
}

.fr_logo_navbar {
    display: flex;
    flex: 1;
    width: 200px;
    vertical-align: center;
}
.fr_icon_navbar-a {
    width: 40px;
    animation: shrink-logo 0.4s;
}

.fr_logo_navbar-a {
    display: flex;
    flex: 1;
    width: 200px;
    vertical-align: center;
    animation: grow-logo 0.4s;
}

a.atalho.nav-link {
    flex: 1;
    /* color: white; */
    color: #000;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

a.nav-link:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #000;
    text-decoration: none;

    
}

a.nav-link.active:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #000;
    text-decoration: none;

}

.toggle_navbar {
    background-color: transparent;
    border: none;
    color: white;
    /* color: black; */

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}
.toggleMenu .toggleMenuItem{
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;
}
.toggleMenu a{
    
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;
}

.toggleMenuButton:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #389492;
    

}


.hide-toggleMenuButton:hover {
    /* color: rgb(35, 43, 27) */
    /* color: rgb(82, 139, 70); */
    color: #389492;


}


.toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;
    
    /* color: #f1bf3b; */
    /* color: black; */
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    justify-content: left;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.toggleMenuButtonCollapse{
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    justify-content: left;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.3s;
}

.toggleMenuButtonCollapse:hover {
    color: black;
}

.hide-toggleMenuButton {
    background-color: transparent;
    border: none;
    color: white;

    /* color: black; */

    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Open {
    /* background-color: rgb(35, 43, 27); */
    background-color: #389492;
    border-radius: 2px 2px 0 0;

}

.toggleMenuButtonCollapse.Open {
    margin-top: 10px;
    margin-left: 5%;
    width: 90%;
    
    transition: all 0.3s;
}

.Open:hover {
    /* color: white; */
    /* color: #389492; */
    /* color: #389492; */
    color: black;
    text-decoration: none;

}


.toggleMenu {
    /* border: solid 2px rgb(35, 43, 27);  */
    border: solid 2px #389492;
    border-radius: 0px 0px 2px 2px;
    border-top: none;
    transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.toggleMenuCollapse {
    border: solid 2px #389492;
    border-radius: 2px 2px 0 0;
    border-top: none;
    margin-left: 5%;
    transition: all 0.4s;
    width: 90%; 
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.toggleMenu.collapse{
    transition: all 0.3s;
    -webkit-transition-duration: 0.3s; /* Safari */
    transition-duration: 0.3s;
}



.toggleMenuItem {
    flex: 1;
    color: white;
    /* color: black; */

    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    transition: all 0.4s;
    padding-left: 25px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.toggleMenuItem:hover {
    color: black;
    text-decoration: none;
}

.hide-toggleMenuItem {
    flex: 1;
    color: white;
    /* color: black; */
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.Open:hover {
    color: #504f4f;
}


.fixedBar{
    position: fixed;
    height: 100vh;
    width: 230px;
    /* animation: grow 0.4s; */
    transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.fixedBar-hide{
    position: fixed;
    height: 100vh;
    width: 78px;
    transition: all 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    /* animation: shrink 0.4s; */
}



/* ///////////////////////////// ANIMAÇÕES ///////////////////////////// */

@keyframes grow{
    from{
        width: 80px;
    }
    to{
        width: 232px;
    }
}

@keyframes grow-logo{
    from{
        width: 40px;
    }
    to{
        width: 200px;
    }
}

@keyframes shrink{
    from{
        width: 232px;
    }
    to{
        width: 80px;
    }
}

@keyframes shrink-logo{
    from{
        width: 200px;
    }
    to{
        width: 40px;
    }
}