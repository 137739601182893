.buttonF2 {
  background-color: #389492;
  border: none;
  color: var(--cor-fonte);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: auto;
  padding: 5px;
  border-radius: 0 4px 4px 0;
}

.FrModalFooter {
  background-color: var(--cor-tema);
}

.FrModalButtonsDiv {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  background-color: var(--cor-tema);
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
}

.FrModalButtonsDiv button:not(.MuiButtonBase-root) {
  border: none;
  width: 100px;
  /* background-color: #389492; */
  background-color: #389492;
  color: var(--cor-fonte);
  /* color: black; */
  
  height: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-left: 10px;
}

.manutFrameContent{
  padding: 10px;
  background-color:var(--cor-tema);
}

.FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
  color: #000;
}

.FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
  transform: translateY(4px);
}
