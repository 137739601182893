.Home {
    display: flex;
}

.HomeContent {
    padding: 15px 30px;
    background-color: var(--cor-tema); 
    /* background-image: url('../../imgs/bg.jpg');   */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    transition-duration: 0.3s;
    overflow-y: auto;
}

.HomeContent a.nav-link {
    background-color: #389492;
    /* background-color: #F7EFEF;  */
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    /* border-radius: 8px; */
    transition-duration: 0.3s;
    color: var(--cor-fonte);   
}

.HomeContent .atalho{
    background-color: #389492;
    /* background-color: #F7EFEF;  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 150px;
    /* border-radius: 8px; */
    transition-duration: 0.3s;
    color: var(--cor-fonte);   
    margin-right: 30px;
    max-width: 200px;
    text-align: center;
    border-radius: 8px;
}
.defaultHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #0A3347;
    border-radius: 6px;
    
}

.defaultHeader h3 {
    border-radius: 6px 0 0 6px;
    background-color: #0A3347;
    color: #F7EFEF;
    /* color: black; */
    font-size: 1.1rem;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
}
.HomeContent a:hover {
    color: var(--cor-fonte-hover);
    text-decoration: none;
}


.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: flex-start;
    flex:1;
}


.iconeAtalho{
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.atalho:hover {
    box-shadow: 0 8px 10px 0 rgba(10, 51, 71, 0.24), 2px 10px 29px 2px rgba(10, 51, 71, 0.19);
}


.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #000 !important;
}

.MuiTableSortLabel-root:hover {
    color: #000 !important;
}

.MuiTableSortLabel-icon {
    color: #000 !important;
}

.MuiTableHead-root .MuiCheckbox-root {
    color: white !important;
}

.atalhoControle {
    margin-bottom: 5px;
    background-color: #389492;
    color: white;
}

.filtersDiv form div {
    display: flex;
    justify-content: left;
}