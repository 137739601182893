.Manut {
    display: flex;
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
}

.ManutContent {
    padding: 15px 30px;
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.ManutContent-frame {
    padding: 20px;
    padding-bottom: 15px;
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.FrCheckBoxLabel {
  color: var(--cor-fonte-hover)
}

.Calendar {
    padding: 20px;
    padding-bottom: 50px;
    background-color: #389492;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
   
}

.tableDivRelacionados {
    margin-top: 20px;
    border: 1px solid #389492;
    padding: 0px;
    border-radius: 5px;
}

.tableDivRelacionados h2 {
    background-color: #389492;
    color: var(--cor-fonte);
    font-size: 1.2rem;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 0px;
}



.FrModalFooter {
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
  }
  
  .FrModalButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 20px;
  }
  
  .FrModalButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 100px;
    background-color:#389492;
    border-radius: 4px;
    color: var(--cor-fonte);
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: var(--cor-fonte-hover);
  }
  
  .FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
  }
  