.defaultReg {
    display: flex;
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
}

.defaultRegContent {
    padding: 15px 30px;    
    background-color: var(--cor-tema);
    transition-duration: 0.4s;
    flex: 1;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 80px;
    /* background-color: #389492; */
    background-color: #389492;
    border-radius: 4px;
    color: var(--cor-fonte);
    transition-duration: 0.4s;
    /* color: black; */
    
    height: 30px;
    transition: all .1s  ease-in-out;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: var(--cor-fonte-hover);
    outline: 1px solid #000;
    outline-offset: -2px;
    transition-duration: 0.4s;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid;
    /* border-bottom: 2px solid #389492; */
    border-bottom: 2px solid #389492;

}

.selectFileButton {
    border: none;
    width: 200px;
    background-color: #389492;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}


.incluiFileButton {
    border: none;
    width: 100px;
    background-color: #389492;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

#html_btn {
	display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regForm{
    display: flex;
    /* justify-content: flex-start; */
    /* height: 100vh; */
    flex: 1;
}

.regButtonsTab {
    display: inline-flex;
    overflow: auto;
    /* flex-flow: row; */
    /* margin-top: 10px; */
    align-items: flex-start; 
    align-content: flex-start; 
    justify-content: flex-start;
    margin-left: 50px;
    
}
.teste {
    display: inline-block;
    overflow: auto;
    /* columns: 100px 2; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */ 
    /* justify-content: flex-end; */
    /* margin-left: 430px; */
    
}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: none;
    width: 30px;
    /* background-color: #389492; */
    background-color: #389492;
    color: white;
    /* color: black; */
    
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-top: 10px;
    
}

.dadosGeraisCliente {
    margin-bottom: 30px;
    border: 2px solid #389492;
    padding: 24px;
}

#regForm h4 {
    flex: 1;
    background-color: #389492;
    color: white;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 0px;
    font-weight: 500;
    border-top: 2px solid #389492;
}

.grupoEdits {
    border: 2px solid #389492;
    padding: 20px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.grupoTitulo {
    flex: 1;
    background-color: #389492;
    color: white;
    white-space: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-indent: 20px;
    margin-bottom: 0px;
    font-weight: 500;
    padding-top: 4px;
    display: flex;
    vertical-align: middle;
}

.regRelacionados {
    border: 2px solid #389492;
}

.atalhoRelacionados {
    background-color: #389492;
    color: white;
    vertical-align: center;
}

.atalhoRelacionados svg {
    margin-right: 5px;
}

.manutReg.Relacionados {
    padding: 10px;
}


.grupoEdits button {
    padding: 5px;
    border: none;
    background-color: #389492;
    /* background-color: var(--cor-tema); */
    border-radius: 6px;
    transition-duration: 0.4s;
    margin-left: 10px;
    margin-bottom: 10px;    
    transition: all .1s  ease-in-out;
  }
  .manutReg .register {
    flex: 1;
    /* background-color: #003459; */
    border: 1px solid #389492;
    margin-left: 30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .manutReg .register .buttons {
    /* height: 25px; */
    align-self: flex-end;
    margin-bottom: 10px;
  }
  
  .fields {
    flex: 1;
    padding-left: 20px;
    padding-top: 20px;
    
    
  }
  
  .tableReg{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}