.filtersDiv {
    border-bottom: 2px solid #389492;
    /* border-bottom: 2px solid #10035c; */
    padding-bottom: 15px;
    padding-top: 15px;
}

.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: left;
    flex-wrap: wrap;
    flex-direction: column;
}

.filters {
    display: flex;
    flex-direction: column;
}

.filters div {
    display: flex;
    justify-content: flex-start
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.tableDiv-frame {
    padding-bottom: 30px;
    border-bottom: 2px solid #389492;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    /* background-color: #389492; */
    background-color: #389492;
    border-radius: 4px;
    color: var(--cor-fonte);
    /* color: black; */
            
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: var(--cor-fonte-hover);
    transition-duration: 0.4s;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    @page {size: A4 landscape; }

    * {
        color: black !important; 
        font-size: 0.6rem !important;
    }

    .MuiTableContainer-root {
        overflow-x: hidden !important;
    }
}