.select {
    background-color: var(--cor-tema);
    color: var(--cor-placeholder);
    min-height: 16px;
    height: 30px;
    /* z-index: 20000 !important; */
}
.ant-select-selection-search {
    color: var(--cor-placeholder);
}



.ant-select-item-option-content {
    font-size: 0.8rem;
    color: var(--cor-placeholder);
    background-color: var(--cor-tema);
    /* z-index: 20000 !important; */

}
/* .ant-select-item-option-selected:hover{
    background-clip: content-box;
    font-size: 0.8rem;
    color: var(--cor-placeholder);
    background-color: #007bff;
    z-index: 20000 !important;

} 
*/
.ant-select-item-option:hover {
    /* background-clip: content-box; */
    /* background-color: #007bff; */
    /* z-index: 20000 !important; */

}

.ant-select-dropdown{
    background-color: var(--cor-tema);
    border-color: var(--cor-tema);
}


.ant-select-selection-item {
    font-size: 1rem;
    color: var(--cor-fonte-hover);
    text-align: left;
    /* z-index: 20000 !important; */
}

.ant-select-selection-placeholder{
    text-align: left;
}

.ant-select-selection-placeholder::after {
    
    color: var(--cor-placeholder);
    font-size: 1rem;
    /* opacity: 1; */
}

.ant-select-item-option-active .ant-select-item-option-content {
    
    transition: 0.4s ease-out;
    background-color: rgba(0, 0, 0, 0.01);
}

.ant-select-selection-search{
    color: var(--cor-fonte-hover);
}