.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    
    color: var(--cor-fonte-hover);
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    /* border: 1px solid rgba(73, 87, 59, 0.733); */
    border: 1px solid #389492;

    /* color: #389492; */
    color: #389492;
}

.tableEdit:active, .tableDelete:active {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
}